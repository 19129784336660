// client/src/pages/ClientHistoryPage.js

import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Grid
} from '@mui/material';
import { format } from 'date-fns';
import { getHistory } from '../services/reservationService';
import { useParams } from 'react-router-dom';

function ClientHistoryPage() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { userId } = useParams();

  const fetchHistory = async () => {
    setLoading(true);
    try {
      // const response = await axios.get('/user/history');
      const response = await getHistory(); // your service call
      setReservations(response.data);
    } catch (error) {
      console.error('Error fetching history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch history', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Reservation History
      </Typography>

      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Paper sx={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Horse</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No reservations found.
                  </TableCell>
                </TableRow>
              ) : (
                reservations.map((res) => (
                  <TableRow key={res.ReservationID}>
                    <TableCell>
                      {res.FirstName} {res.LastName}
                    </TableCell>
                    <TableCell>{res.HorseName}</TableCell>
                    <TableCell>
                      {format(new Date(res.ReservationDate), 'yyyy-MM-dd')}
                    </TableCell>
                    <TableCell>{res.StartTime}</TableCell>
                    <TableCell>{res.EndTime}</TableCell>
                    {/* If Status != 'active', color the text red */}
                    <TableCell
                      sx={{
                        color: res.Status !== 'active' ? 'red' : 'inherit',
                      }}
                    >
                      {res.Status}
                    </TableCell>
                    <TableCell>{res.Reason}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Paper>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ClientHistoryPage;
