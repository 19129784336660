import React, { useState, useEffect } from "react";
import { getHorses } from "../services/authService";

import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  TextField, // Import TextField for the search input
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 200,
  },
}));


export const getFullImageUrl = (imagePath) => {
  if (!imagePath) {
    return "/placeholder.jpg"; // Fallback image
  }

  if(imagePath.includes('http')){
    return imagePath;

  }

  // Otherwise, assume it's a server path and prepend the base URL

  let newimageurl=`${process.env.REACT_APP_BASE_URL}${imagePath}`;

  return newimageurl;
};
    
    
    


function ShowHorsesPage() {
  const classes = useStyles();
  const [horses, setHorses] = useState([]); // Original list of horses
  const [searchTerm, setSearchTerm] = useState(""); // Search input value
  const [filteredHorses, setFilteredHorses] = useState([]); // Filtered list of horses

  useEffect(() => {
    const fetchHorses = async () => {
      try {
        const response = await getHorses();
        setHorses(response.data);
        setFilteredHorses(response.data); // Initialize filtered horses
      } catch (err) {
        console.error(err);
      }
    };

    fetchHorses();
  }, []);

  // Handle search input changes
  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);

    if (searchValue === "") {
      // If search term is empty, show all horses
      setFilteredHorses(horses);
    } else {
      // Filter horses based on search term
      const filtered = horses.filter((horse) => {
        const horseName = horse.HorseName ? horse.HorseName.toLowerCase() : "";
        const horseGender = horse.Gender ? horse.Gender.toLowerCase() : "";
        const horseCost = horse.Cost !== null && horse.Cost !== undefined ? horse.Cost.toString() : "";

        return (
          horseName.includes(searchValue.toLowerCase()) ||
          horseGender.includes(searchValue.toLowerCase()) ||
          horseCost.includes(searchValue)
        );
      });
      setFilteredHorses(filtered);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h5" gutterBottom>
        Available Horses
      </Typography>

      {/* Search Input Field */}
      <TextField
        label="Search Horses"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <Grid container spacing={4}>
        {filteredHorses.map((horse) => (
          <Grid item xs={12} sm={6} md={4} key={horse.HorseID}>
            <Card>
              <CardMedia
                className={classes.media}
                image={getFullImageUrl(horse.ImageURL)}//{horse.ImageURL || "/placeholder.jpg"}
                title={horse.HorseName}
              />
              <CardContent>
                <Typography variant="h6">{horse.HorseName || "Unknown Name"}</Typography>
                <Typography variant="body2">Gender: {horse.Gender || "Unknown"}</Typography>
                <Typography variant="h6">Session Price: ${horse.Cost !== null && horse.Cost !== undefined ? horse.Cost : "N/A"}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {filteredHorses.length === 0 && (
          <Typography variant="body1">No horses found.</Typography>
        )}
      </Grid>
    </Container>
  );
}

export default ShowHorsesPage;
